export const locale = {
  NETWORK_ERRORS: [
    'Failed to fetch',
    'NetworkError when attempting to fetch resource.',
  ],
  NO_DATA: 'No data provided.',
  NO_DATA_AVAILABLE: 'Sorry, no data available.',
  NO_SEARCH_RESULTS: 'No results for your search. Please try another keyword.',
  NO_RESULTS_FOUND: 'No results found.',
  ADD: 'Add',
  SAVE: 'Save',
  OK: 'OK',
  OPEN: 'Open',
  BACK: 'Back',
  CLOSE: 'Close',
  CREATE: 'Create',
  CREATED: 'created',
  UPDATE: 'Update',
  UPDATED: 'updated',
  SAVE_AND_CLOSE: 'Save and close',
  RENAME: 'Rename',
  EDIT: 'Edit',
  CANCEL: 'Cancel',
  BRAND: 'Brand',
  STATUS: 'Status',
  DELETE: 'Delete',
  PUBLISH: 'Publish',
  PUBLISHED: 'Published',
  ARCHIVE: 'Archive',
  DUPLICATE: 'Duplicate',
  REMOVE: 'Remove',
  CHANGE: 'Change',
  CONFIRM: 'Confirm',
  NEW_CATEGORY: 'New category',
  CATEGORIES: 'Categories',
  CATEGORY: 'Category',
  EDIT_CATEGORY: 'Edit Category',
  YES: 'Yes',
  NO: 'No',
  EMAIL: 'Email',
  PASSWORD: 'Password',
  CURRENT_PASSWORD: 'Current password',
  NEW_PASSWORD: 'New password',
  CONFIRMATION_PASSWORD: 'Confirmation password',
  CHANGE_PASSWORD: 'Change password',
  RESET_PASSWORD: 'Reset password',
  REMOVE_PROFILE: 'Remove profile',
  SAVE_CHANGES: 'Save changes',
  PROCEED: 'Proceed',
  HEADER_TITLE: 'Stories',
  SUBMIT: 'Submit',
  LOGIN: 'Login',
  SEARCH: 'Search',
  FILTER: 'Filter',
  PREVIEW: 'Preview',
  PROJECT_PREVIEW: 'Project Preview',
  TEMPLATE_PREVIEW: 'Template Preview',
  REPLAY: 'Replay',
  PROCESS: 'process',
  DOWNLOAD: 'Download',
  STORIES: 'Stories',
  PROJECTS: 'Projects',
  TEMPLATES: 'Templates',
  ALL_TEMPLATES: 'All Templates',
  NOTIFICATIONS: 'Notifications',
  EDIT_USER_PROFILE: 'Editing User Profile',
  USERS_MANAGEMENT: 'Users management',
  SYSTEM_MANAGEMENT: 'System management',
  VIDEO_EDITOR: 'Video Editor',
  HOME_HEADING: 'Opus/Someday Web App',
  PAGE_NOT_FOUND: '404 Page Not Found',
  CREATE_VIDEO_STORY: 'Create video story',
  USER_INFO: 'User info',
  LAST_CHANGES: 'Last changes:',
  CREATE_PROJECT: 'Create Project',
  EDIT_PROJECT: 'Edit Project',
  OPEN_PROJECT: 'Open project',
  RENAME_PROJECT: 'Rename Project',
  PROJECT_TITLE: 'Project title',
  PROJECT_TYPE: 'Project type',
  ORDER: 'Order',
  TEMPLATE_NAME: 'Template name',
  ENTER_PROJECT_TITLE: 'Please enter project info:',
  CHANGE_PROJECT_TITLE: 'Please change project info:',
  CREATED_PROJECT: 'created project',
  SELECTED_PROJECT: 'selected project',
  DURATION_VALIDATION: 'Duration cannot be empty or less than 1 second',
  LOGIN_SUCCESS: 'You have successfully logged in',
  LOGOUT_SUCCESS: 'You have logged out',
  VIDEO_LOAD_ERROR: 'Error loading video. Please try to reload page.',
  MEDIA_LOAD_ERROR: 'Error loading media file. Please try to reload page.',
  ITEM_DELETE_ERROR: 'Error during layer delete. Please try to reload page.',
  TAP_TO_REPLAY: 'Tap to replay',
  READY_TO_DOWNLOAD: 'Ready to Download',
  RENDERING: 'Rendering',
  IN_LINE: 'In-Line',
  RENDERING_FAILED: 'Rendering failed',
  FILE_DELETED:
    'This file or related project was deleted and cannot be downloaded anymore.',
  TOGGLE_PASS: 'Toggle password visibility',
  CANCEL_RENDERING: 'Cancel rendering',
  actionString: (action, item) => `${action} ${item}`,
  actionTextString: (action, item) =>
    `Do you want to ${action.toLowerCase()} ${item}?`,
  SET_NEW_PASSWORD: 'Please set new password',
  CREATE_NEW_PASSWORD: 'Please create password',
  CONFIRM_PASSWORD: 'Confirm your password',
  CREATE_PASSWORD: 'Create password',
  YOUR_CURRENT_PASSWORD: 'your current password',
  YOUR_NEW_PASSWORD: 'your new password',
  YOUR_FIRST_NAME: 'your first name',
  YOUR_LAST_NAME: 'your last name',
  YOUR_EMAIL: 'your email',
  FIRST_TIME_LOGIN: 'First time login? Click',
  FIRST_TIME_LOGIN2: 'to set your password.',
  CREATE_TEMPLATE: 'Create template',
  EDIT_TEMPLATE: 'Edit template',
  FOR_COMPANY: 'For company',
  NAME: 'Name',
  COLOR: 'Color',
  ACTIONS: 'Actions',
  CROPPING: 'Cropping',
  INSERT: 'Insert',
  TEXT: 'Text',
  TRANSFORM_TEXT: 'Transform',
  SPACING: 'Spacing',
  LINE_HEIGHT: 'L Height',
  MAX_CHARACTERS: 'Max Characters',
  SCALE_UP: 'Scale Up',
  ADD_LOGO: 'Add Logo',
  WHITE_LOGO: 'White Logo',
  BLACK_LOGO: 'Black Logo',
  LOGO_POSITION: 'Logo Position',
  LOGO_BRAND: 'Logo Brand',
  TEXT_TYPING_EFFECT: 'Text Typing Effect',
  FONT: 'Font',
  FONT_SIZE: 'Font size',
  FONT_WEIGHT: 'Weight',
  SIZE: 'Size',
  TYPE: 'Type',
  ANIMATION_TYPE: 'Animation Type',
  DURATION: 'Duration, s',
  DELAY: 'Delay, s',
  BORDER_WIDTH: 'Width (px)',
  ANIMATION_EASING: 'Animation Easing',
  DISCRETE_SLIDER: 'Discrete Slider',
  BACKGROUND: 'Background',
  BORDER: 'Border',
  UNDO: 'Undo',
  REDO: 'Redo',
  ADD_OBJECT: 'Add element',
  ADD_ANIMATION: 'Add animation',
  REMOVE_ANIMATION: 'Remove animation',
  EFFECTS: 'Effects',
  ANIMATION: 'Animation',
  APPEARANCE: 'Appearance',
  DISAPPEARANCE: 'Disappearance',
  RESPONSIVE_WARNING:
    'This app is not currently optimized for small screens. Please use another device.',
  LAYERS: 'Layers',
  LAYER: 'Layer',
  OBJECT_INFO: 'Info',
  SEND_MESSAGE: 'Send message',
  StaticText: {
    FIVE_MIN_AGO: '5 min ago',
  },
  System: {
    SYSTEM_MANAGEMENT: 'System management',
    STOP_INSTANCE: 'Stop instance',
    START_INSTANCE: 'Start instance',
    AWS_STATUS: 'AWS Instance status: ',
    SERVICE_STATUS: 'Video Processing service status: ',
    UPDATE_STATUS: 'Update status',
    RESTART_BACKEND_SERVICE: 'Restart backend service',
    RESTART_BACKEND: 'Restart backend',
  },
  User: {
    ADD_NEW_USER: 'Add new user',
    PERSONAL_PROFILE: 'Personal profile',
    USERS: 'Users',
    FIRST_NAME: 'First name',
    LAST_NAME: 'Last name',
    MY_PROFILE: 'My Profile',
    LOGOUT: 'Log Out',
    ROLE: 'Role',
    COMPANY: 'Company',
    EMAIL_ADDRESS: 'Email Address',
    USERNAME: 'Username',
    CREATE_USER: 'Create user',
    CURRENT_PASSWORD: 'Current password',
    NEW_PASSWORD: 'New password',
    CONFIRM_REMOVE_USER: 'Please, confirm the removing of the user profile',
    USER_SUCCESSFULLY: 'User was successfully',
  },
  OBJECTS: {
    MEDIA: 'Media',
    OBJECTS: 'Objects',
    IMAGE: 'Image',
    VIDEO: 'Video',
    TEXT: 'Text',
    RECT: 'Rectangle',
    LINE: 'Line',
    PLACEHOLDER: 'Placeholder',
    ELLIPSE: 'Ellipse',
  },
  Actions: {
    CROP: 'Crop',
    UPLOAD_MEDIA: 'Upload Media',
    FLIP_VERTICAL: 'Flip\n Vertical',
    FLIP_HORIZONTAL: 'Flip\n horizontal',
    REMOVE: 'Remove',
    SEND_BACKWARD: 'Send\n Backward',
    BRING_FORWARD: 'Bring\n Forward',
    RESET: 'DEV_RESET',
    DONE: 'Done',
    CANCEL: 'Cancel',
  },
  Messages: {
    CONFIRM_CANCEL_RENDERING: (item = 'current project') =>
      `Are you sure you want to cancel rendering the ${item}`,
    CONFIRM_ITEM_DELETE: (item = 'item') =>
      `Are you sure you want to delete this ${item}?`,
    PLEASE_ENTER: (item = 'valid information') => `Please enter ${item}`,
    PLEASE_ENTER_YOUR: (item = 'info') =>
      `Please enter your ${item.toLowerCase()}`,
    ITEMS_PER_PAGE: (items = 'Items') => `${items} per page`,
    REQUIRED_FIELD: 'Please fill in the required field',
    INVALID_EMAIL: 'Invalid email address',
    NOT_VALID: (field) => `${field} is not valid`,
    HAS_SPACES: (field) => `${field} contains whitespaces`,
    SLIDE_WAS_DELETED: (item) => `Slide ${item} was deleted`,
    PROJECT_WAS_DELETED: (item) => `Project ${item} was deleted`,
    FIELD_CHARS: (item) => `Field should have more than ${item} characters`,
    MUST_BE_BIGGER_THEN: (item) => `Field must be bigger than ${item}`,
    MUST_BE_EQUAL_OR_BIGGER: (item) =>
      `Field must be equal or bigger than ${item}`,
    MUST_BE_LOWER_THEN: (item) => `Field must be lower than ${item}`,
    MUST_BE_EQUAL_OR_LESS: (item) => `Field must be equal or less than ${item}`,
    MUST_BE_A_TYPE: (type) => `Field must be a ${type}`,
    MIN_CHARACTERS_LAYERS_NAME: (item) => `minimum ${item} characters`,
    MAX_CHARACTERS_LAYERS_NAME: (item) => `maximum ${item} characters`,
    AUTH_PROBLEMS:
      'It seems You have problems with authorization. Please login with proper credentials.',
    SERVER_PROBLEMS:
      'It seems You have problems with service. Please try again later.',
    PUBLISH_ALERT:
      'Please note, that the published template becomes visible for the Content Managers of the selected company. Published template is not editable anymore. Publish this template?',
    ARCHIVE_ALERT:
      'Are you sure you want to archive this template? Please note, the template will be no more visible for the Content Managers. This will not affect existing projects.',
    DELETE_TEMPLATE_ALERT: (template) =>
      `Are you sure you want to delete "${template}" template?`,
    DELETE_CATEGORY_ALERT: (category) =>
      `Are you sure you want to delete "${category}" Category? All templates from this Category will be available in All Templates section.`,
    PUBLISHED_NOTICE: 'Published or archived templates are not editable.',
    PUBLISHED: 'Template published',
    ARCHIVED: 'Template archived',
    EDITED: 'Template changed',
    CREATED_CATEGORY: 'Category created',
    CATEGORY_EDITED: 'Category changed',
    VIDEO_DURATION_NOTIFICATION:
      'Please note that one of the videos is longer than 15 sec',
    ADD_IMAGE_VIDEO:
      "Use this button to add static images (logos, photos, stickers etc.) or videos (background etc.). Please note, that the Content manager won't be able to edit or remove static images/videos.",
    ADD_PLACEHOLDER:
      "Use this button to add placeholders for adding content. Please note, the Content Manager won't be able to change placeholder's position, sizes and added effects/transitions (if any)",
    FILE_SHOULD_BE_MEDIA_FILE: 'File should be a media file',
    DELETE_OBJECT_UNAVAILABLE: 'Nothing to delete. Please select object',
    UNABLE_TO_DELETE: 'Unable to delete. Object is locked',
    SELECT_ONLY_ONE_FILE: 'Please select only one file',
    SELECT_PLACEHOLDER: 'Please select placeholder',
    SYSTEM_RESTART:
      'Please note that stopping of the instance can take up to 10 minutes. When the instance was stopped the next starting can be only after 7-10 minutes, so if you see the message like "The instance is not in a state from which it can be started" it means that the instance is not ready to be started and try to start later.',
  },
  Navigations: {
    UNDO: 'Undo',
    CLOSE: 'Close',
    SET: 'Set',
  },
  LockedProjectWarning:
    'Ooops, someone has been editing the project. The editing option is temporary locked for you. Please, try again later',
  LockedTemplateWarning:
    'Ooops, someone has been editing the template. The editing option is temporary locked for you. Please, try again later',
  ModalHeaders: {
    HOW_DOWNLOAD_STORY: 'How would you like to download?',
    CHOOSE_RESOLUTION_OF_RENDERING: 'Choose resolution of rendering file',
  },
  ModalButtons: {
    CURRENT_SLIDE: 'CURRENT SLIDE',
    WHOLE_PROJECT: 'WHOLE PROJECT',
  },
  Grid: {
    GRID: 'Grid',
    GRID_SIZE: 'Grid size',
    SNAP_TO_GRID: 'Snap to grid',
  },
  NotificationActionTypes: {
    SLIDE_UNDO: 'slideUndo',
    PROJECT_UNDO: 'projectUndo',
    SET_PASSWORD: 'setPassword',
  },
};
