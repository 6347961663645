import { oneOf, string } from 'prop-types';
import classes from './SlideLogo.module.scss';
import React from 'react';

const SlideLogo = ({ logoType, company, dimensions, logoPosition }) => {
  const slideLogo = `./assets/images/${company?.toLowerCase()}_logo_${logoType}.png`;
  const className = `${classes.slideLogo} ${
    company === 'SOMEDAY' ? classes.somedayLogo : ''
  }`;

  const styles = { width: dimensions?.width };
  if (logoPosition === 'center') {
    switch (company) {
      case 'SOMEDAY':
        styles.top = '43%';
        styles.maxWidth = company === 'SOMEDAY' ? '70%' : '67%';
        styles.left = '15%';
        break;
      case 'OPUS':
        styles.top = '46%';
        styles.maxWidth = '67%';
        styles.left = '15%';
        break;
      case 'OPUS PANTS':
        styles.top = '49%';
        styles.maxWidth = '76%';
        styles.left = '12%';
        break;
    }
  } else {
    if (company === 'OPUS PANTS') {
      styles.bottom = '5%';
      styles.left = '28%';
      styles.maxWidth = '44%';
    }
  }

  return (
    <img src={slideLogo} alt={slideLogo} className={className} style={styles} />
  );
};

SlideLogo.propTypes = {
  logoType: oneOf(['white', 'black']),
  company: string.isRequired,
  logoPosition: oneOf(['bottom', 'center', null]),
};

export default SlideLogo;
