import { environment } from '../../environments/environment';
import { DISCRETE_ZOOM_SLIDER_MIN_VALUE } from './transitions';
export const TRANSITION_NAMES = Object.freeze({
  None: 'none',
  Fade: 'fade',
  Directional: 'directional',
});

export const TRANSITION_NAMES_OPTIONS = Object.freeze([
  { value: TRANSITION_NAMES.None, label: 'None' },
  { value: TRANSITION_NAMES.Fade, label: 'Fade' },
  { value: TRANSITION_NAMES.Directional, label: 'Directional' },
]);

export const STORY_TRANSITION_NAMES_OPTIONS = Object.freeze([
  { value: TRANSITION_NAMES.None, label: 'None' },
  { value: TRANSITION_NAMES.Fade, label: 'Fade' },
  { value: TRANSITION_NAMES.Directional, label: 'Directional' },
]);

export const TRANSITION_DURATIONS = Object.freeze({
  None: '0',
  Fast: '1',
  Normal: '1.5',
  Slow: '2',
  VerySlow: '2.5',
  Slowest: '3',
});

export const LOGO_POSITIONS = Object.freeze({
  Bottom: 'bottom',
  Center: 'center',
});

export const LOGO_BRANDS = Object.freeze({
  Opus: 'OPUS',
  Someday: 'SOMEDAY',
  OpusPants: 'OPUS PANTS',
});

export const TRANSITION_DURATION_OPTIONS = Object.freeze([
  { value: TRANSITION_DURATIONS.Fast, label: TRANSITION_DURATIONS.Fast },
  {
    value: TRANSITION_DURATIONS.Normal,
    label: TRANSITION_DURATIONS.Normal,
  },
  { value: TRANSITION_DURATIONS.Slow, label: TRANSITION_DURATIONS.Slow },
  {
    value: TRANSITION_DURATIONS.VerySlow,
    label: TRANSITION_DURATIONS.VerySlow,
  },
  {
    value: TRANSITION_DURATIONS.Slowest,
    label: TRANSITION_DURATIONS.Slowest,
  },
]);

export const LOGO_POSITION_OPTIONS = Object.freeze([
  { value: LOGO_POSITIONS.Bottom, label: LOGO_POSITIONS.Bottom },
  { value: LOGO_POSITIONS.Center, label: LOGO_POSITIONS.Center },
]);

export const LOGO_BRAND_OPTIONS = Object.freeze([
  { value: LOGO_BRANDS.Opus, label: LOGO_BRANDS.Opus },
  { value: LOGO_BRANDS.Someday, label: LOGO_BRANDS.Someday },
  { value: LOGO_BRANDS.OpusPants, label: LOGO_BRANDS.OpusPants },
]);

export const SLIDE_PLACEHOLDER_URL_SHORT = `static/slide-placeholder.jpg`;
export const SCALE_PLACEHOLDER_URL_SHORT = `static/scale-placeholder.jpg`;
export const PREVIEW_PLACEHOLDER_URL_SHORT = `static/preview-placeholder.jpg`;

export const BUILD_FULL_FILE_PATH = (filePath) =>
  `${environment.apiUrl}/${filePath}`;

export const SLIDE_DEFAULT_DURATION = 3;
export const TEMPLATE_SLIDE_DEFAULT_DURATION = 5;
export const SLIDE_TRANSITION_DEFAULT_DURATION = 2;
export const SLIDE_FADE_DEFAULT_DURATION = 1.5;

export const ANIMATION_DEFAULT_DELAY = 3000;

// Used for fallback
export const ANIMATION_DEFAULT_DURATION = 100;

export const PREVIEW_SLIDE_PLACEHOLDER = {
  duration: 0,
  imageUrl: PREVIEW_PLACEHOLDER_URL_SHORT,
  index: 0,
  transitionDuration: TRANSITION_DURATIONS.Normal,
  transitionName: TRANSITION_NAMES.Fade,
  fadeOut: true,
  fadeIn: true,
  zoomIn: false,
  zoomInRange: DISCRETE_ZOOM_SLIDER_MIN_VALUE,
};

export const LIST_SLIDE_PLACEHOLDER = {
  ...PREVIEW_SLIDE_PLACEHOLDER,
  imageUrl: SLIDE_PLACEHOLDER_URL_SHORT,
};

export const SLIDE_TYPE = Object.freeze({
  TemplateBased: 'TEMPLATE_BASED',
  AssetBased: 'ASSET_BASED',
});
export const PREVIEW_SLIDE_ID = '2c74-43ca-ac28-a315d927779f';
export const PREVIEW_CANVAS_DATA = JSON.parse(
  `{"type":"Canvas","hasGrid":false,"objects":[],"preview":"static/preview-placeholder.jpg","slideId":"${PREVIEW_SLIDE_ID}","version":"3.6.6","gridSize":20,"projectId":"d919-46a1-a75b-71484a219a1b","snapAngle":0,"background":"#ffffff","savedColor":"#ffffff","templateId":"771d-4f72-9a7c-d3d45989a6e3","enableColor":true,"objectSnapping":false,"viewportTransform":[0.7848970251716247,0,0,0.7848970251716247,0,0],"canvasCurrentWidth":343,"canvasInitialWidth":343}`
);
